.project{
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
  background-color: #fff;
  padding: 15px;
  margin-top: 21px;
}

.darkContent .project{
  background-color: rgba(194, 2, 242, 0.30);
}

.projectTitle{
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000;
  width: 100%;
}

.projectText{
  font-size: 18px;
  color: #000;
  min-height: 54px;
  max-height: 54px;
  overflow: hidden;
  margin-top: 16px;
  width: 100%;
}

.projectCategory{
  height: 20px;
  border: 1px solid #191A1E;
  color: #000;
  padding: 0px 5px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 68px;
  margin-top: 16px;
}

.darkContent .projectTitle,
.darkContent .projectText,
.darkContent .projectCategory{
  color: #F1B8FF;
}

.darkContent .projectCategory{
  border-color: #F1B8FF;
}

@media (max-width: 991px){
  .project{
    margin-top: 24px;
  }
}