.siteModeContent{
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.darkContent .siteModeContent{
  background-color: #4A015E;
}

.siteMode{
  width: 21px;
  height: 21px;
  background: url("/img/icon/sitemode.png") no-repeat;
  background-size: 100% auto;
  background-position: center 0px;
  cursor: pointer;
}

.darkContent .siteMode{
  width: 24px;
  height: 24px;
  background-position: center -24px;
}

@media (max-width: 991px){
  .siteModeContent{
    position: absolute;
    left: 16px;
    top: 20px;
    width: 48px;
    height: 48px;    
  }
  .siteMode{
    width: 32px;
    height: 32px;
  }
  .darkContent .siteMode{
    width: 32px;
    height: 32px;
    background-position: center -32px;
  }
}