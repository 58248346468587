.hsTextContent{
  display: flex;
  flex-direction: column;
  margin-top: 46px;
}

.hsText{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #CD05FF;
  margin-bottom: 10px;
}

.hsUserName{
  font-weight: 700;
  font-size: 48px;
  line-height: 44px;
  color: #C202F2;
  margin-bottom: 15px;
}

.hsJob{
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: rgba(25, 26, 30, 0.7);
  margin-bottom: 20px;
}

.darkContent .hsJob{
  color: rgba(241, 184, 255, 0.7);
}

.hsParagh{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(25, 26, 30, 0.4);
  max-width: 413px;
}

.darkContent .hsParagh{
  color: #F1B8FF;
}

.homeYPhoto{
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  width: 100%;
  max-width: 350px;
  max-height: 350px;
  height: auto;
  object-fit: cover;
  -o-object-fit: cover;
  -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
}


@media (max-width: 991px){

  .homeSide .row{
    flex-direction: column-reverse;
  }

  .hsImgSide{
    display: flex;
    justify-content: center;
  }

  .hsTextContent{
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 30px;
  }

  .hsUserName{
    font-size: 36px;
    line-height: 41px;
    margin-bottom: 12px;
  }

  .hsJob{
    font-size: 24px;
    line-height: 29px;
  }

  .hsParagh{
    max-width: 326px;
  }

  .homeYPhoto{
    width: 211px;
    height: 204px;
  }
}