.html {
  scroll-behavior: smooth !important;
}


body{
  font-family: 'Roboto', sans-serif!important;
  font-weight: 400!important;
}

section {
  margin-top: 104px;
}

.sectionTitle{
  font-weight: 700;
  font-size: 42px;
  line-height: 49px;
  color: #C202F2;
}

.showAllBtn{
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  color: #CD05FF;
  background-color: rgba(194, 2, 242, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-decoration: none;
  -webkit-transition: all ease-in 0.2s;
  -moz-transition: all ease-in 0.2s;
  transition: all ease-in 0.2s;
}

.showAllBtn:hover{
  color: #CD05FF;
  text-decoration: none;
  background-color: rgba(194, 2, 242, 0.2);
}

.darkContent .showAllBtn{
  background-color: rgba(219, 83, 253, 0.1);
}

.darkContent .showAllBtn:hover{
  background-color: rgba(219, 83, 253, 0.2);
}

a:hover{
  text-decoration: inherit!important;
}

.btn-primary{
  color: #CD05FF!important;
  background-color: rgba(194, 2, 242, 0.1)!important;
  border-color: rgba(194, 2, 242, 0.1)!important;
}

.btn-primary:hover{
  background-color: rgba(219, 83, 253, 0.2)!important;
  background-color: rgba(219, 83, 253, 0.2)!important;
}

.darkContent .btn-primary{
  color: #F1B8FF!important;
  background-color: rgba(219, 83, 253, 0.1)!important;
  background-color: rgba(219, 83, 253, 0.2)!important;
}

@media (min-width: 992px){
  .showAllBtn{
    position: absolute;
    top: 13px;
    right: 0px;
  }
}

@media (max-width: 991px){
  section{
    margin-top: 60px;
  }

  .sectionTitle{
    font-size: 36px;
    line-height: 42px;
    width: 100%;
    text-align: center;
  }

  .showAllBtn{
    margin: 0 auto;
    margin-top: 24px;
  }
}