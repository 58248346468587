.card{
  -webkit-border-radius: 8px!important;
  -moz-border-radius: 8px!important;
  border-radius: 8px!important;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
  margin-top: 21px;
}

.darkContent .card{
  background-color: rgba(194, 2, 242, 0.30)!important;
}

.darkContent .card-title, .darkContent .card-text{
  color: #F1B8FF!important;
}

.card-img, .card-img-top{
  -webkit-border-top-left-radius: 8px!important;
  -moz-border-top-left-radius: 8px!important;
  border-top-left-radius: 8px!important;
  -webkit-border-top-right-radius: 8px!important;
  -moz-border-top-right-radius: 8px!important;
  border-top-right-radius: 8px!important;
}

@media (max-width: 991px){
  .card{
    margin-top: 24px;
  }
}