.navbar{
  padding: .5rem 0px!important;
}

.darkContent .navbar{
  background-color: #600079!important;
}

.yLogo{
  width: 32px;
  height: 32px;
  background: url("/img/icon/yago-logo2.png") no-repeat;
  background-size: 100% auto;
  display: inline-block;
}

.darkContent .yLogo{
  background-position-y: -32px;
}

.navbar-light .navbar-nav .nav-link{
  color: rgba(30, 25, 25, 0.50)!important;
  font-size: 14px;
  line-height: 16px;
  margin-right: 13px;
}

.navbar-light .navbar-nav .nav-link:focus{
  color: rgba(30, 25, 25, 0.50)!important;
}

.darkContent .navbar-light .navbar-nav .nav-link,
.darkContent .navbar-light .navbar-nav .nav-link:focus{
  color: rgba(255, 255, 255, 0.5)!important;
}

.navbar-expand-lg .navbar-nav .nav-link{
  padding: 2px 0px!important;
}

.navbar-light .navbar-nav .nav-link.active{
  color: #C202F2!important;
  border-bottom: 1px solid #C202F2;
}

.darkContent .navbar-light .navbar-nav .nav-link.active{
  color: #CD05FF!important;
  border-bottom: 1px solid #CD05FF;
}

.navbar-light .navbar-nav .nav-link:hover{
  color: #C202F2!important;
}

.darkContent .navbar-light .navbar-nav .nav-link:hover{
  color: #CD05FF!important;
}

.resumeLink{
  width: 65px;
  height: 25px;
  line-height: 25px;
  background-color: rgba(194, 2, 242, 0.10);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #C202F2;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  margin-right: 50px;
}

.resumeLink:hover{
  color: #C202F2;
  text-decoration: inherit;
}

.darkContent .resumeLink{
  background-color: rgba(194, 2, 242, 0.40);
}

.menuOther{
  width: auto;
  display: flex;
  align-items: center;
}


.navbar-light .navbar-toggler{
  color: transparent!important;
  border-color: inherit!important;
}

.show-navbar{
  width: 32px;
  height: 32px;
  background: transparent;
  padding: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;  
  border: none;
}

.darkContent .show-navbar svg path{
  stroke: #F1B8FF;
}

.close-navbar{
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 17px;
  right: 16px;
  background: transparent;
  padding: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;  
  border: none;
}

.darkContent .close-navbar svg path{
  fill: #F1B8FF;
}

@media (max-width: 575px){
  .navbar-light .navbar-nav .nav-link{
    font-size: 32px;
    line-height: 38px;
  }
}

@media (min-width: 576px) and (max-width:991px){
  .navbar-light .navbar-nav .nav-link{
    font-size: 38px;
    line-height: 45px;
  }
}

@media (max-width: 991px){
  .navbar-collapse.collapse.show{
    position: fixed!important;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .darkContent .navbar-collapse.collapse{
    background-color: #600079;
  }

  .navbar-nav.ml-auto{
    margin: inherit!important;
  }

  .navbar-light .navbar-nav .nav-link{
    color: rgba(30, 25, 25, 0.3)!important;
    margin-right: 0px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
  }

  .darkContent .navbar-light .navbar-nav .nav-link{
    color: rgba(30, 25, 25, 0.5)!important;
  }

  .navbar-light .navbar-nav .nav-link:last-child{
    margin-bottom: 0px;
  }

  .navbar-light .navbar-nav .nav-link.active{
    border: none!important;
  }

  

  .navbar{
    padding: 1.125rem 1px!important;
  }

  
}